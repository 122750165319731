import {Injectable} from '@angular/core';
import {User, UserManager, WebStorageStateStore, UserManagerSettings} from 'oidc-client';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _userManager: UserManager = new UserManager(getClientSettings());
  private _user: User;

  constructor() {
    this._userManager.getUser().then(user => {
      this._user = user;
    });

    this._userManager.events.addAccessTokenExpiring(() => {
      //console.log('AccessTokenExpiring ->');
      this.signinSilent();
    });
    
    this.getAuthenticatedUser();
  }

  /**
   * Get the User object for the currently authenticated user
   */
  getAuthenticatedUser() {
    this._userManager.getUser().then(user => {
      if (user && !user.expired) {
        this._user = user;
      } else {
        this.removeAuthenticatedUser().then(() => this.login());
      }
    });
  }

  /**
   * Remove from any storage the currently authenticated user
   */
  removeAuthenticatedUser() {
    return this._userManager.removeUser();
  }

  /**
   * To trigger a silent request (via an iframe) to the authorization endpoint.
   * Need 'silent_redirect_uri' configuration
   * it returns user object
   */
  signinSilent() {
    this._userManager.signinSilent().then((user) => {
      //console.log("callback happened", user);
      this._user = user;
    })
    .catch((error) => { console.error(error) });
  }

  login(): Promise<any> {
    return this._userManager.signinRedirect();
  }

  logout(): Promise<any> {
    return this._userManager.signoutRedirect();
  }

  isLoggedIn(): boolean {
    return this._user && this._user.access_token && !this._user.expired;
  }

  getAccessToken(): string {
    return this._user ? this._user.access_token : null;
  }

  signoutRedirectCallback(): Promise<any> {
    return this._userManager.signoutRedirectCallback();
  }
}

export function getClientSettings(): UserManagerSettings {

  return {
      authority: environment.identityProviderUrl,
      client_id: environment.clientId,
      redirect_uri: `${environment.clientRoot}assets/oidc-login-redirect.html`,
      scope: 'openid profile applicationList ampapi',
      response_type: 'id_token token',
      post_logout_redirect_uri: `${environment.clientRoot}logout?client_id=${environment.clientId}`,
      userStore: new WebStorageStateStore({store: window.localStorage}),
      silent_redirect_uri: `${environment.clientRoot}assets/silent-redirect.html`,
      automaticSilentRenew: true,
      revokeAccessTokenOnSignout: true,
      clockSkew: 500,
    };

}
